/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { METRICS_SUPPORTING_USAGE_LIMITS } from 'embercom/helpers/billing/usage-helper';
import ajax from 'embercom/lib/ajax';
import { INCLUDED_TIERS_FROM_PRICING_ENDPOINT } from 'embercom/lib/billing';
import { hash } from 'rsvp';
import { type Router } from '@ember/routing';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';
import type UsageReminderModel from 'embercom/models/usage-reminder';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { getArticleFromDistribution } from 'embercom/lib/articles/article-distribution';
import type SubscriptionEndingService from 'embercom/services/subscription-ending-service';

export default class BillingUsageRoute extends Route {
  @service declare permissionsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare router: Router;
  @service declare customerService: $TSFixMe;
  @service declare store: Store;
  @service declare subscriptionEndingService: SubscriptionEndingService;

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  beforeModel(transition: Transition) {
    if (!transition.to.queryParams.from_billing_admin) {
      this.permissionsService.ensurePermissionWhenTransitioning(
        'can_access_billing_settings',
        transition,
      );
    }
    taskFor(this.loadData).perform();

    if (
      !this.app.onFinForPlatformsOrPricing5 ||
      !this.app.hasActiveSubscription ||
      (this.customer?.inTrial && !this.app.onFinForPlatforms)
    ) {
      this.router.transitionTo('apps.app.settings.subscription.billing.index');
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'billing_usage_page',
    });
  }

  model() {
    return hash({
      contract: this.store.findRecord('billing/contract', this.app.id),
      pmdasData: this.getPmdasData(),
      currentBillingPeriodCharges: this.getCurrentBillingPeriodCharges(),
      usageReminders: this.store.findAll(
        'usage-reminder',
        this.app.id,
      ) as unknown as Array<UsageReminderModel>,
      usageLimits: this.getUsageLimits(),
      pricingFaqLink: this.pricingFaqLink,
      shouldShowSubscriptionEndingBanner:
        this.subscriptionEndingService.shouldShowSubscriptionEndingBanner(false),
    });
  }

  async getPmdasData() {
    return ajax({
      url: '/ember/pricing_model_daily_app_stats/fetch_for_current_billing_cycle',
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
      },
    });
  }

  // These charges are new and coming from Stripe. To be used on the new Billing Summary.
  async getCurrentBillingPeriodCharges() {
    if (this.appService.app.canUseBillingSummaryRedesign) {
      return ajax({
        url: '/ember/billing/current_billing_period_charges',
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
        },
      });
    }
    return [];
  }
  async getUsageLimits() {
    let usageLimitsArray: $TSFixMe[] = [];
    METRICS_SUPPORTING_USAGE_LIMITS.forEach((item) => {
      this.store.queryRecord(item.tableName, {});
      usageLimitsArray.push({
        metric: item.metric,
        tableName: item.tableName,
      });
    });

    return usageLimitsArray;
  }

  afterModel(_model: $TSFixMe, _transition: Transition) {
    if (!this.customerService.currentPrice) {
      return this.customerService.fetchCurrentPrice({
        includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
      });
    }
  }

  @task *loadData() {
    yield Promise.all([
      this.customerService.loadData({
        fetchPrices: false,
      }),
    ]);
  }

  get pricingFaqLink() {
    return getArticleFromDistribution(
      'BILLING_USAGE_ROUTE__HEADER_FAQ',
      this.app,
      this.customerService,
    )?.articleLink;
  }
}
