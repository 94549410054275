/* RESPONSIBLE TEAM: team-ai-agent */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';

export default class AppsAppSettingsAiAutomationExternalAiRoute extends Route {
  @service declare router: RouterService;
  @service declare permissionsService: $TSFixMe;

  queryParams = {
    redirect: {
      refreshModel: false,
      replace: true,
    },
  };

  beforeModel(transition: Transition) {
    let app = this.modelFor('apps.app') as {
      canUseFeature: (feature: string) => boolean;
    };

    if (!app.canUseFeature('show-global-opt-out-settings-page')) {
      return this.router.transitionTo('apps.app.settings');
    }

    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_workspace_settings',
      transition,
    );
  }
}
